<template>
  <div
    class="select-locations radius-anchor max-w-xl mx-auto"
    :class="config.theme.product_style_list_view"
  >
    <h2 class="text-2xl leading-9 tracking-tight sm:text-4xl sm:leading-10 pr-7">
      {{ $t('locations.title') }}
    </h2>

    <div
      class="mt-5 locations-wrapper loader"
      :class="[{ 'items-loading': items_loaded === false }]"
    >
      <!-- @if($location->hasFunction(['delivery', 'takeaway', 'quickorder', 'menu'])) -->
      <a
        v-for="(location, index) in locations"
        :key="index"
        class="content-container location radius-anchor loader-anchor"
        @click="selectLocation(location)"
      >
        <img
          v-if="location.attributes.image"
          class="radius-anchor"
          :src="location.attributes.image"
          alt=""
        >
        <div class="bg-content-container">
          <p class="text-base leading-5 font-bold uppercase opacity-60">{{ location.attributes.city }}</p>
          <h3 class="text-xl leading-7 text-main">{{ location.attributes.name }}</h3>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

//libs
import * as _api from '../lib/api';
import * as _state from '../lib/state';
import * as _location from '../lib/location';
import * as _transaction from '../lib/transaction';

export default {
    data() {
        return {
            locations: [],
            items_loaded: false,
        };
    },
    computed: {
        config() {
            return _state.get('config/getConfig');
        },
    },
    async mounted() {
        if (this.config.settings.location_selection == false) {
            this.$router.push({ name: 'landing' });

            return;
        }

        _location.init();

        setTimeout(
            //page loading
            this.setItemsLoaded,
            1200
        );

        let locations = await _api.get('locations');
        // this.locations = locations;
        this.locations = locations.filter((location) => {
            return (
                location.attributes.functions.delivery == true ||
                location.attributes.functions.elockers == true ||
                location.attributes.functions.menu == true ||
                location.attributes.functions.quickorder == true ||
                location.attributes.functions.reservation == true ||
                location.attributes.functions.tables == true ||
                location.attributes.functions.takeaway == true ||
                location.attributes.functions.visitors == true ||
                location.attributes.functions.wallet == true
            );
        });

        // redirect to the location
        if (this.locations.length === 1) {
            this.selectLocation(this.locations[0]);
        }
    },

    methods: {
        setItemsLoaded() {
            this.items_loaded = true;
        },

        async selectLocation(location) {
            // set the slug
            axios.defaults.headers.common['Location'] = location.attributes.slug;

            // clear transaction
            _transaction.clear();

            await _location.init(location.attributes.slug);

            let functions = {
                delivery: location.attributes.functions.delivery ? location.attributes.functions.delivery : false,
                takeaway: location.attributes.functions.takeaway ? location.attributes.functions.takeaway : false,
                quickorder: location.attributes.functions.quickorder ? location.attributes.functions.quickorder : false,
                tables: location.attributes.functions.tables ? location.attributes.functions.tables : false,
                menu: location.attributes.functions.menu ? location.attributes.functions.menu : false,
                elockers: location.attributes.functions.elockers ? location.attributes.functions.elockers : false,
                reservation: location.attributes.functions.reservation ? location.attributes.functions.reservation : false,
                visitors: location.attributes.functions.visitors ? location.attributes.functions.visitors : false,
                wallet: location.attributes.functions.wallet ? location.attributes.functions.wallet : false,
            };

            // redirect to deliverymethod
            if (functions.delivery && functions.takeaway) {
                this.$router.push('/' + location.attributes.slug + '/aflevermethode');
            }

            // redirect to delivery
            else if (functions.delivery) {
                this.$router.push('/' + location.attributes.slug + '/aflevermethode');
            }

            // redirect to takeaway
            else if (functions.takeaway) {
                this.$router.push('/' + location.attributes.slug + '/afhalen');
            }

            // redirect to quickorder
            else if (functions.quickorder) {
                this.$router.push('/' + location.attributes.slug + '/quickorder');
            }

            // redirect to menu
            else if (functions.menu) {
                this.$router.push('/' + location.attributes.slug + '/menu');
            }

            // redirect to reservation
            else if (functions.reservation) {
                this.$router.push('/' + location.attributes.slug + '/reserveren');
            }

            // redirect to lockers
            else if (functions.elockers) {
                this.$router.push('/' + location.attributes.slug + '/lockers');
            }
            // redirect to wallet
            else if (functions.wallet) {
                this.$router.push('/' + location.attributes.slug + '/wallet/opwaarderen');
            }
        },
    },
};
</script>
